<template>
  <section>
    <div class="w-full bg-white py-8">
      <div class="w-full">
        <div v-if="dataUser.actualizadoPor" class="w-full flex justify-end px-8 text-gray-400">
          <i class="pi pi-refresh text-xs mr-2"></i>
          Ultima actualización por: {{ dataUser.actualizadoPor }} el día {{ dayjs(dataUser.actualizadoALas).format('YYYY/MM/DD') }} a las {{ dayjs(dataUser.actualizadoALas).format('HH:mm:ss') }}
        </div>
        <div class="md:w-6/12 text-center">
          <div class="flex justify-center my-4">
            <div
              class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
            >
              <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative" v-if="imagen">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full border-blue-600 border-2 w-full h-full"
                  :src='imagen'
                />
              </div>
              <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative" v-else>
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full border-blue-600 border-2"
                  src='../../../../../../../images/user.jpg'
                />
              </div>
              <button
                @click="displayMaximizable = true"
                class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-blue-600 rounded-full p-2"
              >
                <i class="pi pi-pencil w-4 h-4 text-white"></i>
              </button>
              <Dialog header="Agregar imagen" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div class="my-2">
                    <div class="mt-2">
                      <FilePond ref="pond"/>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                  <Button label="Guardar" class="w-full" @click="closeMaximizable()" autofocus />
                </template>
              </Dialog>
            </div>
          </div>
          <div v-if="dataUser.foto_perfil">
            <div v-if="dataUser.foto_perfil.urlPath" class="w-full flex justify-center text-blue-600">
              <i class="pi pi-image mr-2"></i>
              <p class="font-bold mr-2">
                {{ dataUser.foto_perfil.fileName }}
              </p>
              <button class="bg-red-600 rounded-full m-0 flex justify-center items-center w-5 h-5" @click="dataUser.foto_perfil = {}">
                <i class="pi pi-times text-white" style="font-size: 0.6rem;"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:flex">
        <div class="px-4 md:px-20 w-full md:w-6/12">
          <div class="field flex gap-2">
            <div class="grid w-full my-2">
              <label class="labelColor" for="firt_name">Nombre</label>
              <div class="md:flex gap-4 w-full">
                <InputText @keydown="nameLettersAndSpaces($event)" v-model="dataUser.firt_name" id="firt_name" class="w-full md:w-9/12" type="text" placeholder="" />
              </div>
            </div>
            <div class="grid w-full my-2">
              <label class="labelColor" for="last_name">Apellidos</label>
              <div class="md:flex gap-4 w-full">
                <InputText @keydown="nameLettersAndSpaces($event)" v-model="dataUser.last_name" id="name" class="w-full md:w-9/12" type="text" placeholder="" />
              </div>
            </div>
          </div>
          <div class="field grid w-full my-2">
            <label class="labelColor" for="user_name">Nombre de usuario</label>
            <InputText @keydown="nameKeydown($event)" id="user_name" autocomplete="off" v-model="dataUser.user_name" class="w-full mb-2" type="text" placeholder="" />
          </div>
          <div class="field grid w-full my-2">
            <label class="labelColor" for="email">Email</label>
            <InputText v-model="dataUser.email" id="email" autocomplete="off" class="w-full mb-2" type="text" placeholder="" />
          </div>
          <div class="field flex gap-2">
            <div class="grid w-full my-2">
              <label for="password">Clave usuario</label>
              <Password @keydown="nameKeydownPass($event)" autocomplete="off" panelClass="hidden" style="width: 100%;" v-model="dataUser.password" id="password" class="w-full" toggleMask></Password>
            </div>
            <div class="grid w-full my-2">
              <label for="passwordConfirmation" class="labelColor">Repetir clave</label>
              <Password id="passwordConfirmation" autocomplete="off" panelClass="hidden" style="width: 100%;" v-model="dataUser.confirmation_password" class="w-full" toggleMask></Password>
              <span class="text-red-600 text-xs">{{ dataUser.password !== dataUser.confirmation_password ? 'Las contraseñas deben coincidir' : '' }}</span>
            </div>
          </div>
          <div v-if="store.getters['auth/isAdmin']" class="py-2 flex gap-2 items-center">
            <InputSwitch v-model="dataUser.admin" />
            <p>Usuario administrador</p>
          </div>
        </div>
        <div class="px-4 md:px-20 w-full md:w-6/12">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
            <div class="field grid w-full">
              <label class="labelColor" for="number_document">Numero de documento</label>
              <div class="flex gap-2">
                <div class="w-2/12">
                  <Dropdown
                    v-model="dataUser.type_document_id"
                    :options="documentTypes"
                    @change="dataUser.number_document = ''"
                    optionValue="id"
                    optionLabel="name"
                    class="w-full mb-2 border-1 border-gray-200 rounded-md"
                    placeholder="">
                    <template #value="slotProps">
                      <div class="p-dropdown-car-value" v-if="slotProps.value">
                        <span>{{slotProps.value}}</span>
                      </div>
                      <span v-else>
                        {{slotProps.placeholder}}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                </div>
                <div class="w-10/12">
                  <InputText @keydown="nameNumberDocumentCaract($event)" maxlength="20" v-model="dataUser.number_document" id="number_document" class="w-full sm:mb-2 mb-0" v-if="dataUser.type_document_id === 'CC' || dataUser.type_document_id === 'RC' || dataUser.type_document_id === 'TI'" />
                  <InputText @keydown="nameKeydown($event)" maxlength="20" v-model="dataUser.number_document" id="number_document" class="w-full sm:mb-2 mb-0" type="text" v-else />
                </div>
              </div>
            </div>
            <div class="field grid w-full">
              <label class="labelColor" for="phone">Telefono</label>
              <div class="flex gap-2">
                <InputMask id="phone" mask="(999) 999-9999" v-model="dataUser.phone" class="w-full sm:mb-2 mb-0" type="text" placeholder="" />
              </div>
            </div>
          </div>
          <div class="field grid w-full my-2">
            <label class="labelColor" for="departamento">Departamento/Municipio</label>
            <Dropdown
              v-model="dataUser.localizacion"
              :showClear="true"
              :options="localizaciones"
              class="w-full mb-2 border-1 border-gray-200 rounded-md"
              :filter="true"
              optionLabel="name"
              optionValue="id"
              placeholder="Seleccione"></Dropdown>
          </div>
          <div class="field grid w-full my-2">
            <label class="labelColor" for="direccion">Dirección</label>
            <div class="md:flex gap-4 w-full">
              <InputText v-model="dataUser.direccion" id="direccion" class="w-full sm:mb-2 md:mb-4" type="text" placeholder="" />
            </div>
          </div>
          <div class="w-full grid grid-cols-2 gap-2" v-if="esEmpleado">
            <div class="w-full">
              <label class="labelColor" for="direccion">Sede</label>
              <div class="md:flex gap-4 w-full">
                <Dropdown
                  @change="guardarDataEmpleado"
                  v-model="dataEmpleado.sede"
                  :options="sedes"
                  optionLabel="name"
                  optionValue="id"
                  :filter="true"
                  placeholder="Seleccione"
                  class="rounded-md border-gray-300 w-full"
                />
              </div>
            </div>
            <div class="w-full">
              <label class="labelColor" for="direccion">Area</label>
              <div class="md:flex gap-4 w-full">
                <Dropdown
                  @change="guardarDataEmpleado"
                  v-model="dataEmpleado.area"
                  :options="areas"
                  optionLabel="name"
                  optionValue="id"
                  :filter="true"
                  placeholder="Seleccione"
                  class="rounded-md border-gray-300 w-full"
                />
              </div>
            </div>
            <div class="w-full">
              <label class="labelColor" for="direccion">Cargo</label>
              <div class="md:flex gap-4 w-full">
                <Dropdown
                  @change="guardarDataEmpleado"
                  v-model="dataEmpleado.cargo"
                  :options="cargos"
                  optionLabel="name"
                  optionValue="id"
                  :filter="true"
                  placeholder="Seleccione"
                  class="rounded-md border-gray-300 w-full"
                />
              </div>
            </div>
          </div>
          <div class="md:flex sm:grid sm:grid-cols-1 gap-2 md:justify-between my-8">
            <div class="flex">
              <Checkbox class="mr-4 rounded-full h-5 w-5 flex items-center justify-center" inputId="binary" v-model="dataUser.state" :binary="true" />
              <p>Activo / Inactivo</p>
            </div>
            <div class="flex">
              <Checkbox class="mr-4 rounded-full h-5 w-5 flex items-center justify-center" @change="cambiarEsEmpleado" inputId="binary" v-model="esEmpleado" :binary="true" />
              <p>Empleado</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, computed, watch, onMounted } from 'vue'
import Password from 'primevue/password'
import Dropdown from 'primevue/dropdown'
import usuariosStore from '../../../../../../../stores/usuarios.store'
import UsuariosService from '../../../../../../../services/usuarios.service'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import FilePond from './uploadImage.vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'informacionCrearUsuario',
  components: {
    Password,
    Dropdown,
    FilePond
  },
  setup () {
    // Service
    const _UsuariosService = ref(new UsuariosService())
    // References
    const pond = ref()
    const displayMaximizable = ref(false)
    const cedulaEncontrada = ref('')
    const esEmpleado = ref(usuariosStore.getters._empleado)
    const dataEmpleado = ref({
      sede: '',
      area: 0,
      cargo: ''
    })
    // Stores
    const store = useStore()
    // Computed
    const localizaciones = computed(() => usuariosStore.getters._localizaciones)
    const imagenIncomming = computed(() => usuariosStore.getters._imagen)
    const imagen = ref(imagenIncomming)
    const userData = computed(() => usuariosStore.getters._usuario_data)
    const usuario = computed(() => usuariosStore.getters._usuario)
    const persona = computed(() => usuariosStore.getters._usuario_persona)
    const grupo = computed(() => usuariosStore.getters._usuario_grupo)
    const documentTypes = computed(() => usuariosStore.getters._type_documents)
    const adjunto = computed(() => FilePondStore.getters.adjunto)
    const sedes = computed(() => usuariosStore.getters._sedes)
    const areas = computed(() => usuariosStore.getters._areas)
    const cargos = computed(() => usuariosStore.getters._cargos)
    const esEmpleadoStore = computed(() => usuariosStore.getters._empleado)
    const dataEmpleadoStore = computed(() => usuariosStore.getters._data_empleado)
    // Data
    const dataUser = computed(() => usuariosStore.getters._usuario_data)
    // Methods
    const selectedLocation = ref({})
    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        dataUser.value.foto_perfil = {
          urlPath: adjunto.value.urlPath,
          fileName: 'perfil'
        }
        displayMaximizable.value = false
      })
    }
    const verImagen = () => {
      _UsuariosService.value.ver(imagen.value)
    }
    watch(dataUser.value, (n, v) => {
      usuariosStore.commit('setUsuarioData', n)
    })
    watch(dataEmpleadoStore, (n, v) => {
      dataEmpleado.value = n
    })
    watch(esEmpleadoStore, (n, v) => {
      esEmpleado.value = n
    })
    const nameKeydown = (e) => {
      if (/^[a-zA-Z0-9]*$/g.test(e.key) === false) {
        e.preventDefault()
      }
      if (e.key === 'Dead') {
        const input = document.getElementById('user_name')
        input.blur()
      }
    }
    const nameKeydownPass = (e) => {
      if (e.key === ' ') {
        e.preventDefault()
      }
    }
    const nameNumberDocumentCaract = (e) => {
      if (e.key !== 'Backspace' && !e.ctrlKey) {
        if (/[^0-9]/.test(e.key)) {
          e.preventDefault()
        }
      }
      if (e.key === 'Dead') {
        const input = document.getElementById('number_document')
        input.blur()
      }
    }
    const nameLettersAndSpaces = (e) => {
      if (/[^A-z\s]/.test(e.key)) {
        e.preventDefault()
      }
    }
    const guardarDataEmpleado = () => {
      usuariosStore.commit('setDataEmpleado', dataEmpleado.value)
    }
    const cambiarEsEmpleado = () => {
      usuariosStore.commit('setEsEmpleado', esEmpleado.value)
    }
    watch(userData.value, (n, v) => {
      const keys = ['direccion', 'admin', 'email', 'password', 'firt_name', 'last_name', 'localizacion', 'number_document', 'password', 'state', 'user_name', 'foto_perfil', 'phone', 'confirmation_password', 'actualizadoPor', 'actualizadoALas']
      for (const [key, value] of Object.entries(userData.value)) {
        if (keys.some(a => a === key)) {
          dataUser.value[key] = value
        }
      }
      dataUser.value.type_document_id = userData.value.type_document_id ? userData.value.type_document_id : 'CC'
    })
    onMounted(() => {
      dataEmpleado.value = dataEmpleadoStore.value
    })
    return {
      dataUser,
      documentTypes,
      usuario,
      persona,
      grupo,
      localizaciones,
      selectedLocation,
      closeMaximizable,
      displayMaximizable,
      pond,
      verImagen,
      imagen,
      cedulaEncontrada,
      nameKeydown,
      nameKeydownPass,
      nameLettersAndSpaces,
      nameNumberDocumentCaract,
      dayjs,
      esEmpleado,
      sedes,
      dataEmpleado,
      areas,
      cargos,
      guardarDataEmpleado,
      cambiarEsEmpleado,
      esEmpleadoStore,
      store
    }
  }
}
</script>

<style lang="scss" scoped>
  .labelColor {
    color: #7F7E81 !important;
    font-size: 12px/14px !important;
  }
  ::v-deep(.p-inputtext.p-component) {
    width: 100% !important;
  }
  ::v-deep(.p-checkbox .p-checkbox-box) {
    border-radius: 50%;
  }
  ::v-deep(.p-dropdown-trigger) {
    display: none;
  }
</style>
